import React, { ChangeEvent, useState, useContext, useEffect } from "react";
import axios from "axios";
import "./PersonalDetails.css";
import {
  alpha,
  Autocomplete,
  Box,
  // Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCamera,
  faEnvelope,
  faPerson,
  faPersonDress,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { Info } from "@mui/icons-material";
import { Item } from "../../../components/ItemComponent/Item.tsx";
import {
  PersonalInfoContext,
  PersonalInfo,
  defaultPersonalInfo,
} from "../../../context/PersonalInfoContext.tsx";
import { Link, useLocation } from "react-router-dom";

export type InitialContactProps = {
  onEmailSubmit: (email: string) => void;
};

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean; // Define error prop as optional boolean
}

const CustomInputStyleImg = styled("input")<CustomInputProps>(
  ({ theme, error }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderRadius: 10,
    borderColor: error
      ? theme.palette.error.main
      : theme.palette.mode === "light"
        ? "#E0E3E7"
        : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(
        error
          ? "rgba(255, 0, 0, 0.25) 0 0 0 0.2rem"
          : theme.palette.primary.main,
        0.25,
      )} 0 0 0 0.2rem`,
    },
  }),
);

// const CustomInputStyleImg = styled("input")(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//     backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
//     border: "1px solid",
//     borderRadius: 10,
//     borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
//     fontSize: 16,
//     padding: "10px 12px",
//     transition: theme.transitions.create([
//       "border-color",
//       "background-color",
//       "box-shadow",
//     ]),

//     "&:focus": {
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     },
//   },
// }));

const CustomInputStyle = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderRadius: 10,
    borderColor: error
      ? theme.palette.error.main
      : theme.palette.mode === "light"
        ? "#E0E3E7"
        : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(
        error
          ? "rgba(255, 0, 0, 0.25) 0 0 0 0.2rem"
          : theme.palette.primary.main,
        0.25,
      )} 0 0 0 0.2rem`,
    },
  },
}));

// const CustomInputStyle = styled(InputBase)(({ theme }) => ({
//   "label + &": {
//     marginTop: theme.spacing(3),
//   },
//   "& .MuiInputBase-input": {
//     backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
//     border: "1px solid",
//     borderRadius: 10,
//     borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
//     fontSize: 16,
//     padding: "10px 12px",
//     transition: theme.transitions.create([
//       "border-color",
//       "background-color",
//       "box-shadow",
//     ]),

//     "&:focus": {
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     },
//   },
// }));

const CustomRadioStyle = styled(RadioGroup)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    size: "large",
  },
  "& .MuiFormControlLabel-root": {},
  "& .MuiFormControlLabel-label": {
    fontSize: "1.2em",
  },
  "& .MuiRadio-root": {
    padding: "10px",
  },
  "& .MuiRadio-root .FontAwesomeIcon": {
    fontSize: "1.3em", // Adjust the size of the icon
  },
}));

interface PersonalDetailsProps {
  showEdit: boolean;
  message: string;
  promptInfo: boolean;
}

export default function PersonalDetails({
  showEdit,
  message,
  promptInfo,
}: PersonalDetailsProps) {
  // Use state to manage form data
  const { personalInfo, setPersonalInfo, isLoading } =
    useContext(PersonalInfoContext);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  // const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean | undefined>(true);
  const [isFilled, setIsFilled] = useState<boolean | undefined>(false);
  const [emailRef, setEmailRef] = useState<HTMLDivElement | null>(null);
  const [validPhone, setValidPhone] = useState<boolean>(true);

  const [isLoadingFirstName, setIsLoadingFirstName] = useState(false);
  const [isLoadingMiddleName, setIsLoadingMiddleName] = useState(false);
  const [isLoadingLastName, setIsLoadingLastName] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingGender, setIsLoadingGender] = useState(false);
  const [isLoadingDob, setIsLoadingDob] = useState(false);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [isLoadingCountryEligibility, setIsLoadingCountryEligibility] =
    useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [file, setFile] = useState("");
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);

  const educationOptions = [
    "No high school at all",
    "Some high school, no degree (less than 12 years of school)",
    "High school, no degree (12+ years of school)",
    "High school degree",
    "University degree",
  ];
  
  const [educationLevel, setEducationLevel] = useState<string | undefined>(undefined);
  const [jobExperience, setJobExperience] = useState<string | undefined>(undefined);

  
  const handleEducationChange = (newValue: string | null) => {
    setEducationLevel(newValue || ""); // Set to empty string if null
    setPersonalInfo("education", newValue || ""); // Save to context, set to empty string if falsy

    // Set jobExperience to empty string if education is "University degree" or "High school degree"
    if (newValue === "University degree" || newValue === "High school degree") {
        setPersonalInfo("jobExperience", ""); // Clear job experience
    }
};
  // Save data to local storage whenever personalInfo changes
  // useEffect(() => {
  //   localStorage.setItem("personalInfo", JSON.stringify(personalInfo));
  // }, [personalInfo]);

  const handleJobExperienceChange = (event: any, newValue: string | null) => {
    setJobExperience(newValue || undefined);
    setPersonalInfo("jobExperience", newValue); // Save to context, set to null if falsy
    if (newValue === "no") {
      alert("You are not eligible for the DV lottery.");
    }
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.down("md"));
  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const [allCities, setAllCities] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [filteredCountries1, setFilteredCountries1] = useState<string[]>([]);
  const [filteredCountries2, setFilteredCountries2] = useState<string[]>([]);
  const [selectedCountry1, setSelectedCountry1] = useState<string | null>();
  // localStorage.getItem("selectedCountry1") || null,
  const [selectedCountry2, setSelectedCountry2] = useState<string | null>();
  // localStorage.getItem("selectedCountry2") || null,
  const [selectedCity, setSelectedCity] = useState<string | null>();
  // localStorage.getItem("selectedCity") || null,
  const [countryInput1, setCountryInput1] = useState<string>();
  // localStorage.getItem("countryInput1") || "",
  const [countryInput2, setCountryInput2] = useState<string>();
  // localStorage.getItem("countryInput2") || "",
  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  useEffect(() => {
    const fetchCountries = async () => {
      const storedCountries = localStorage.getItem("countries");
      const storedCities = localStorage.getItem("allCities");
      if (storedCountries && storedCities) {
        setCountries(JSON.parse(storedCountries));
        setAllCities(JSON.parse(storedCities));
      } else {
        setLoadingCountries(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries",
          );
          const data = await response.json();
          const fetchedCountries = data.data.map(
            (country: any) => country.country,
          );
          const fetchedCities = data.data.flatMap(
            (country: any) => country.cities,
          );
          setCountries(fetchedCountries);
          setAllCities(fetchedCities);
          localStorage.setItem("countries", JSON.stringify(fetchedCountries));
          localStorage.setItem("allCities", JSON.stringify(fetchedCities));
        } catch (error) {
          console.error("Error fetching countries:", error);
        } finally {
          setLoadingCountries(false);
        }
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchCities = async (country: string) => {
      const storedCities = localStorage.getItem(`cities_${country}`);
      if (storedCities) {
        setCities(JSON.parse(storedCities));
      } else {
        setLoadingCities(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ country }),
            },
          );
          const data = await response.json();
          const fetchedCities = data.data;
          setCities(fetchedCities);
          localStorage.setItem(
            `cities_${country}`,
            JSON.stringify(fetchedCities),
          );
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setLoadingCities(false);
        }
      }
    };

    const storedCountry = personalInfo.countryOfBirth;
    if (storedCountry) {
      setSelectedCountry1(storedCountry);
      fetchCities(storedCountry);
    } else if (selectedCountry1) {
      fetchCities(selectedCountry1);
    } else {
      setCities(allCities);
    }
  }, [selectedCountry1, allCities]);

  useEffect(() => {
    if (countryInput1) {
      const filtered = countries.filter((country) =>
        country.toLowerCase().startsWith(countryInput1.toLowerCase()),
      );
      setFilteredCountries1(filtered);
    } else {
      setFilteredCountries1([]);
    }
  }, [countryInput1, countries]);

  useEffect(() => {
    if (countryInput2) {
      const filtered = countries.filter((country) =>
        country.toLowerCase().startsWith(countryInput2.toLowerCase()),
      );
      setFilteredCountries2(filtered);
    } else {
      setFilteredCountries2([]);
    }
  }, [countryInput2, countries]);

  useEffect(() => {
    const storedCity = personalInfo.cityOfBirth;
    if (storedCity) {
      setSelectedCity(storedCity);
    }
  }, []);

  const handleCountrySelect1 = (event: any, newValue: string | null) => {
    setSelectedCountry1(newValue);
    setSelectedCity(null);
    setPersonalInfo("cityOfBirth", "");
    localStorage.setItem("selectedCountry1", newValue || "");
    if (newValue) {
      const fetchCities = async () => {
        setLoadingCities(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ country: newValue }),
            },
          );
          const data = await response.json();
          const fetchedCities = data.data;
          setCities(fetchedCities);
          localStorage.setItem(
            `cities_${newValue}`,
            JSON.stringify(fetchedCities),
          );
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setLoadingCities(false);
        }
      };
      fetchCities();
    }
  };

  const handleCountrySelect2 = (event: any, newValue: string | null) => {
    setSelectedCountry2(newValue);
  };

  const handleCitySelect = (event: any, newValue: string | null) => {
    setSelectedCity(newValue);
    localStorage.setItem("selectedCity", newValue || "");
  };

  const handleCountryInput1Change = (event: any, newInputValue: string) => {
    setIsTyping(true);
    setCountryInput1(newInputValue);
    if (!newInputValue) {
      setSelectedCountry1("");
      setSelectedCity(null);
      setPersonalInfo("countryOfBirth", "");
      setPersonalInfo("cityOfBirth", "");
      localStorage.removeItem("selectedCountry1");
      localStorage.removeItem("selectedCity");
    }
  };

  const handleCityInputChange = (event: any, newInputValue: string) => {
    setSelectedCity(newInputValue);
    if (!newInputValue) {
      setPersonalInfo("cityOfBirth", "");
      localStorage.removeItem("selectedCity");
    }
  };

  const handleCountryInput2Change = (event: any, newInputValue: string) => {
    setIsTyping(true);
    setCountryInput2(newInputValue);
    if (!newInputValue) {
      setSelectedCountry2("");
      setPersonalInfo("countryOfEligibility", "");
    }
  };

  const filterOptions = (
    options: string[],
    { inputValue }: { inputValue: string },
  ) => {
    return options
      .filter((option) =>
        option.toLowerCase().startsWith(inputValue.toLowerCase()),
      )
      .slice(0, 10);
  };

  useEffect(() => {
    if (selectedCountry1) {
      setPersonalInfo("countryOfBirth", selectedCountry1);
      localStorage.removeItem("selectedCountry1");
    }
  }, [selectedCountry1, setPersonalInfo]);

  useEffect(() => {
    if (selectedCity) {
      setPersonalInfo("cityOfBirth", selectedCity);
      localStorage.removeItem("selectedCity");
    }
  }, [selectedCity, setPersonalInfo]);

  useEffect(() => {
    if (selectedCountry2) {
      setPersonalInfo("countryOfEligibility", selectedCountry2);
    }
  }, [selectedCountry2, setPersonalInfo]);

  //
  // useEffect(() => {
  //   // Check if isFilled is true and emailRef is available
  //   if (isFilled && emailRef && !showEdit) {
  //     // Smooth scroll to the email element
  //     emailRef.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }, [isFilled, emailRef]);
  useEffect(() => {
    const allowedPaths = [
      "personal-details",
      "address",
      "family-details",
      "review",
    ];
    const url = new URL(window.location.href);
    const applicationId = personalInfo.id;
    const currentPath = url.pathname;
    const pathSegments = currentPath.split("/").filter((segment) => segment);

    const applicationsIndex = pathSegments.indexOf("application");

    if (applicationsIndex !== -1 && applicationId) {
      // Ensure the applicationId is right after "application"
      if (pathSegments[applicationsIndex + 1] !== applicationId) {
        pathSegments.splice(applicationsIndex + 1, 0, applicationId);
      }

      // Retain only allowed paths after the applicationId
      const newSegments = pathSegments.slice(0, applicationsIndex + 2);
      const remainingSegments = pathSegments.slice(applicationsIndex + 2);
      for (const segment of remainingSegments) {
        if (allowedPaths.includes(segment)) {
          newSegments.push(segment);
        } else {
          break;
        }
      }

      const newPath = "/" + newSegments.join("/");
      const newUrl = `${url.origin}${newPath}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [personalInfo.id]);

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  useEffect(() => {
    if (validateEmail(personalInfo.email)) {
      setIsLoadingEmail(false);
      setIsFilled(true);
    }
  }, [personalInfo.email]);

  const readURL = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateEmail = (email: string): boolean => {
    // if (!email) {
    //   return true;
    // }
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    return phoneRegex.test(phone);
  };

  const sendEmail = () => {
    const isValid = validateEmail(personalInfo.email);
    setValidEmail(isValid);
    if (personalInfo.email === "") {
      setValidEmail(true);
    }
    if (isValid) {
      setIsLoadingEmail(false);
    } else {
      setIsLoadingEmail(false);
    }
  };

  // const sendEmailToServer = async () => {
  //   setIsLoadingEmail(true);
  //   try {
  //     const csrfToken = localStorage.getItem("csrfToken");
  //     const headers = {
  //       "Content-Type": "application/json",
  //       "CSRF-Token": csrfToken || "",
  //     };

  //     // Send a request to the server to handle the email submission
  //     const response = await axios.post(
  //       `${import.meta.env.VITE_API_SERVER_URL}/applications`,
  //       {
  //         email: personalInfo.email,
  //         firstname: personalInfo.firstname,
  //         middlename: personalInfo.middlename,
  //         lastname: personalInfo.lastname,
  //       },
  //       { headers: headers, withCredentials: true },
  //     );

  //     if (response.status === 201) {
  //       const data = response.data;
  //       console.log("ok");
  //       if (data.applicationId) {
  //         setIsLoadingEmail(false);
  //         setIsFilled(true);
  //       } else {
  //         setIsLoadingEmail(false);
  //         // Application doesn't exist, create a new one
  //       }
  //       console.log(data.applicationId);
  //     } else {
  //       setIsLoadingEmail(false);
  //       // Handle non-successful responses
  //       console.error("Failed to send email to server");
  //     }
  //   } catch (error) {
  //     setIsLoadingEmail(false);
  //     // Handle network errors or other exceptions
  //     console.error("Error sending email to server:", error);
  //   }
  //   setIsLoadingEmail(false);
  // };

  const minDate = "1900-01-01";
  const currentDate = new Date().toISOString().split("T")[0];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setValidPhone(validatePhone(value));
    }
    if (name === "email") {
      setValidEmail(true);
    }

    if (e.target.files?.[0]) {
      console.log(e.target.files?.[0]);
      console.log(e.target.files);

      if (e.target.files?.[0].type === "image/jpeg") {
        setFile(e.target.files?.[0].name);
        readURL(e.target.files);
        if (e.target.name === "photo") {
          const files = e.target.files;
          const csrfToken = localStorage.getItem("csrfToken");
          setFile(files[0].name);
          const handlePhotoUrl = async (): Promise<any | undefined> => {
            try {
              const formData = new FormData();
              formData.append("image", files[0]);
              const applicationId = personalInfo.id;
              formData.append("applicationId", applicationId);
              formData.append("type", "me");

              const response = await axios.post(
                `${import.meta.env.VITE_PHOTO_SERVER_URL}/upload-and-crop`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "CSRF-Token": csrfToken,
                  },
                  withCredentials: true,
                },
              );
              console.log(
                `File uploaded successfully. URL: ${response.data.Location}`,
              );
              return response.data.imageUrl;
            } catch (error) {
              console.error("Error uploading file:", error);
              console.log("Error uploading file. Please try again.");
            }

            // return 'https://immigrateable.nyc3.cdn.digitaloceanspaces.com/Screenshot%202023-08-01%20at%206.37.56%20PM.png';
          };

          (async () => {
            const photoUrl = await handlePhotoUrl();
            // Here you can use photoUrl as needed
            setPersonalInfo(e.target.name as keyof PersonalInfo, photoUrl);
          })();
        }
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
      }
    } else {
      // if (name === "dob" && !validateYear(value)) {
      //   console.error("Year must be four digits");
      //   return;
      // }
      switch (name) {
        case "firstname":
          setIsLoadingFirstName(true);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);

          break;
        case "middlename":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(true);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        case "lastname":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(true);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        case "email":
          setIsLoadingEmail(true);
          break;
        case "gender":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(true);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        case "dob":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(true);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          if (value.split("-")[0].length !== 4) {
            console.error("Year must be 4 digits");
            return;
          }
          break;
        case "photo":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(true);
          setIsLoadingPhone(false);
          setIsLoadingCountry(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        case "countryOfBirth":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingPhone(false);
          setIsLoadingCountry(true);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        case "cityOfBirth":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingCountry(false);
          setIsLoadingPhone(false);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(true);
          break;
        case "countryOfEligibility":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingCountry(false);
          setIsLoadingPhone(false);
          setIsLoadingCountryEligibility(true);
          setIsLoadingCity(false);
          break;
        case "phone":
          setIsLoadingFirstName(false);
          setIsLoadingLastName(false);
          setIsLoadingMiddleName(false);
          setIsLoadingGender(false);
          setIsLoadingDob(false);
          setIsLoadingPhoto(false);
          setIsLoadingCountry(false);
          setIsLoadingPhone(true);
          setIsLoadingCountryEligibility(false);
          setIsLoadingCity(false);
          break;
        default:
          break;
      }
      if (name in defaultPersonalInfo) {
        setPersonalInfo(name as keyof PersonalInfo, value);
      } else {
        console.error(`${name} is not a valid key of PersonalInfo.`);
      }
    }
  };

  return (
    <Box component="section" className="initial-contact">
      <Container maxWidth="xl">
        <Grid
          container
          rowSpacing={4}
          columnSpacing={4}
          justifyContent="flex-end"
          alignItems="flex-end"
          rowGap={4}
          columnGap={4}
        >
          <Grid
            id="nameBox"
            display="flex"
            container
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Grid display="flex" item flexGrow={1}>
              <Typography
                margin={isXs || isSm ? "auto" : ""}
                // variant="h2"
                variant={isXs || isSm ? "h4" : "h2"}
                fontWeight="bold"
                textAlign="left"
              >
                Personal details
              </Typography>
            </Grid>
            {/* {showEdit ? (
              <Grid display="flex" item>
                <Button
                  component={Link}
                  to="/application/welcome"
                  variant="text"
                  color="primary"
                >
                  edit
                </Button>
              </Grid>
            ) : (
              <></>
            )} */}
          </Grid>
          <Grid
            display="flex"
            marginLeft={isXs || isSm ? "30px" : ""}
            container
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Grid item display="flex" flexDirection="row" xs={12}>
              <Item>
                <Grid
                  display="flex"
                  container
                  flexDirection="row"
                  flexGrow={1}
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item display="flex" flexDirection="column" xs={12}>
                    <Grid
                      container
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <FontAwesomeIcon icon={faUser} size="xl" />
                      </Grid>
                      <Grid item display="flex" flexDirection="column">
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          textAlign="left"
                          ml={2}
                        >
                          Primary applicant
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" flexDirection="row">
                      <Grid item display="flex" flexDirection="column">
                        <Typography variant="body1" textAlign="left">
                          Privacy notice: Your information will not be shared
                          with any 3rd party.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item display="flex" flexDirection="row" xs={12}>
                    <Box
                      component="form"
                      noValidate
                      width="100%"
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          md: "repeat(2, 1fr)",
                          lg: "repeat(3, 1fr)",
                        },
                        gap: 2,
                        rowGap: 4,
                      }}
                    >
                      <FormControl variant="standard">
                        <InputLabel shrink htmlFor="firstname-input">
                          First name
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </InputLabel>
                        <CustomInputStyle
                          error={!personalInfo.firstname && promptInfo}
                          // disabled={showEdit}
                          name="firstname"
                          placeholder="First name"
                          fullWidth
                          value={personalInfo.firstname}
                          id="firstname-input"
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              {isLoading &&
                              isLoadingFirstName &&
                              personalInfo.email ? (
                                <CircularProgress size={20} />
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          }
                        />
                        {!personalInfo.firstname && promptInfo ? (
                          <Typography
                            color="red"
                            variant="body1"
                            textAlign="left"
                          >
                            *Required field
                          </Typography>
                        ) : (
                          ""
                        )}
                      </FormControl>
                      <FormControl variant="standard">
                        <InputLabel shrink htmlFor="middlename-input">
                          Middle name
                        </InputLabel>
                        <CustomInputStyle
                          // disabled={showEdit}
                          name="middlename"
                          placeholder="Middle name"
                          fullWidth
                          value={personalInfo.middlename}
                          id="middlename-input"
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              {isLoading &&
                              isLoadingMiddleName &&
                              personalInfo.middlename ? (
                                <CircularProgress size={20} />
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormControl variant="standard">
                        <InputLabel shrink htmlFor="lastname-input">
                          Last name
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </InputLabel>
                        <CustomInputStyle
                          error={!personalInfo.lastname && promptInfo}
                          // disabled={showEdit}
                          name="lastname"
                          placeholder="Last name"
                          fullWidth
                          value={personalInfo.lastname}
                          id="lastname-input"
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              {isLoading &&
                              isLoadingLastName &&
                              personalInfo.lastname ? (
                                <CircularProgress size={20} />
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          }
                        />
                        {!personalInfo.lastname && promptInfo ? (
                          <Typography
                            color="red"
                            variant="body1"
                            textAlign="left"
                          >
                            *Required field
                          </Typography>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
          <Grid
            marginLeft={isXs || isSm ? "30px" : ""}
            id="emailBox"
            ref={(ref) => setEmailRef(ref)}
            display="flex"
            container
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Grid item display="flex" flexDirection="row" xs={12}>
              <Item>
                <Grid
                  display="flex"
                  container
                  flexDirection="row"
                  flexGrow={1}
                  rowSpacing={2}
                  columnSpacing={2}
                >
                  <Grid item display="flex" flexDirection="column" xs={12}>
                    <Grid
                      container
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <FontAwesomeIcon icon={faEnvelope} size="xl" />
                      </Grid>
                      <Grid item display="flex" flexDirection="column">
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          textAlign="left"
                          ml={2}
                        >
                          Email Address
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container display="flex" flexDirection="row">
                      <Grid item display="flex" flexDirection="column">
                        <Typography variant="body1" textAlign="left">
                          The U.S. Government will send more information to this
                          email address if you get chosen.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    flexDirection="row"
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink htmlFor="email-input">
                        Enter your email address
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </InputLabel>
                      <CustomInputStyle
                        // disabled={showEdit}
                        error={!personalInfo.email && promptInfo}
                        name="email"
                        type="email"
                        placeholder="Email"
                        fullWidth
                        value={personalInfo.email}
                        id="email-input"
                        onChange={handleChange}
                        onBlur={sendEmail}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoadingEmail ? (
                              <CircularProgress size={20} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        }
                        // onInput={sendEmail}
                      />
                      {/* {!validEmail ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          *Required field
                        </Typography>
                      ) : (
                        ""
                      )} */}
                      {!validEmail ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          Please enter a valid email address
                        </Typography>
                      ) : "" ||
                        message == "Please enter a valid email address" ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          {message}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>
          {isFilled ? (
            <>

            {/* Gender and dob */}
              <Grid
                marginLeft={isXs || isSm ? "30px" : ""}
                display="flex"
                container
                justifyContent="flex-end"
                flexDirection="row"
                alignItems="flex-end"
              >
                <Grid item display="flex" flexDirection="row" xs={12}>
                  <Item>
                    <Grid
                      display="flex"
                      container
                      flexDirection="row"
                      flexGrow={1}
                      rowSpacing={2}
                      columnSpacing={4}
                    >
                      <Grid
                        id="otherInfoBox"
                        item
                        display="flex"
                        flexDirection="column"
                        xs={12}
                      >
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Grid
                            item
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          ></Grid>
                          <Grid item display="flex" flexDirection="column">
                            <Typography
                              variant="h5"
                              fontWeight="bold"
                              textAlign="left"
                              ml={0}
                            >
                              Other Info
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item display="flex" flexDirection="row" xs={12}>
                        <Box
                          component="form"
                          noValidate
                          width="100%"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              md: "repeat(2, 2fr)",
                              lg: "repeat(2, 1fr)",
                            },
                            gap: 2,
                            rowGap: 4,
                          }}
                        >
                          <FormControl
                            variant="standard"
                            sx={{ gridColumn: "span 1" }}
                          >
                            <InputLabel shrink htmlFor="gender">
                              Gender
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <Box marginTop={2}>
                              <CustomRadioStyle
                                row
                                value={personalInfo.gender}
                                aria-labelledby="gender-radio-buttons-group-label"
                                name="gender"
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="female"
                                  control={
                                    <Radio
                                      checkedIcon={
                                        <FontAwesomeIcon
                                          icon={faPersonDress}
                                          style={{
                                            color: "#880000",
                                            fontSize: "1.3em",
                                          }}
                                        />
                                      }
                                      icon={
                                        <FontAwesomeIcon
                                          icon={faPersonDress}
                                          style={{
                                            color: "#dedede",
                                            fontSize: "1.3em",
                                          }}
                                        />
                                      }
                                    />
                                  }
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="male"
                                  control={
                                    <Radio
                                      // disabled={showEdit}
                                      checkedIcon={
                                        <FontAwesomeIcon
                                          icon={faPerson}
                                          style={{ fontSize: "1.3em" }}
                                        />
                                      }
                                      icon={
                                        <FontAwesomeIcon
                                          icon={faPerson}
                                          style={{
                                            color: "#dedede",
                                            fontSize: "1.3em",
                                          }}
                                        />
                                      }
                                    />
                                  }
                                  label="Male"
                                />
                              </CustomRadioStyle>

                              {!personalInfo.gender && promptInfo && (
                                <Typography
                                  color="red"
                                  variant="body1"
                                  textAlign="left"
                                >
                                  *Required field
                                </Typography>
                              )}
                            </Box>
                          </FormControl>
                          <FormControl
                            variant="standard"
                            sx={{
                              gridColumn: { lg: "2/4", md: "1/3", xs: "1/2" },
                            }}
                          >
                            <InputLabel shrink htmlFor="dob-input">
                              Date of birth
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <CustomInputStyle
                              error={!personalInfo.dob && promptInfo}
                              // disabled={showEdit}
                              name="dob"
                              type="date"
                              placeholder="Date of birth"
                              fullWidth
                              value={personalInfo.dob}
                              id="dob-input"
                              onChange={handleChange}
                              inputProps={{ min: minDate, max: currentDate }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {isLoading && isLoadingDob ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    ""
                                  )}
                                </InputAdornment>
                              }
                            />
                            {!personalInfo.dob && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
              </Grid>

{/* Country */}
              <Grid
                marginLeft={isXs || isSm ? "30px" : ""}
                display="flex"
                container
                justifyContent="flex-end"
                flexDirection="row"
                alignItems="flex-end"
              >
                <Grid item display="flex" flexDirection="row" xs={12}>
                  <Item>
                    <Grid
                      display="flex"
                      container
                      flexDirection="row"
                      flexGrow={1}
                      rowSpacing={2}
                      columnSpacing={2}
                    >
                      <Grid item display="flex" flexDirection="column" xs={12}>
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Grid
                            id="eligibilityBox"
                            item
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            {/* Icon for the section */}
                          </Grid>
                          <Grid item display="flex" flexDirection="column">
                            {/* Title of the section */}
                            <Typography
                              variant="h5"
                              fontWeight="bold"
                              textAlign="left"
                              ml={0}
                            >
                              Country of Eligibility
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item display="flex" flexDirection="row" xs={12}>
                        {/* Form control for the grid */}
                        <Box
                          component="form"
                          noValidate
                          width="100%"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              md: "repeat(2, 1fr)",
                              lg: "repeat(3, 1fr)",
                            },
                            gap: 2,
                            rowGap: 4,
                          }}
                        >
                          {/* FormControl for Country where you were born */}
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="country-input">
                              Country where you were born
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <Autocomplete
                              className={`input-auto ${!personalInfo.countryOfBirth && promptInfo ? "error-border" : ""}`}
                              // disabled={showEdit}

                              noOptionsText="Type to search"
                              value={personalInfo.countryOfBirth}
                              onChange={handleCountrySelect1}
                              inputValue={countryInput1 || ""}
                              onInputChange={handleCountryInput1Change}
                              disableClearable
                              // onInputChange={(event, newInputValue) =>
                              //   setCountryInput1(newInputValue)
                              // }
                              options={filteredCountries1}
                              filterOptions={filterOptions}
                              renderInput={(params) => (
                                <TextField
                                  placeholder="Country where you were born"
                                  // sx={{ padding: "5px" }}
                                  {...params}
                                  variant="standard"
                                  fullWidth
                                  InputProps={{
                                    disableUnderline: true,
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {loadingCountries && isTyping ? (
                                          <CircularProgress size={20} />
                                        ) : null}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {!personalInfo.countryOfBirth && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                          {/* FormControl for City where you were born */}
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="city-input">
                              City where you were born
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <Autocomplete
                              // disabled={showEdit}
                              className={`input-auto ${!personalInfo.cityOfBirth && promptInfo ? "error-border" : ""}`}
                              noOptionsText="Type to search"
                              value={personalInfo.cityOfBirth}
                              onChange={handleCitySelect}
                              disableClearable
                              onInputChange={handleCityInputChange}
                              inputValue={selectedCity || ""}
                              options={cities}
                              filterOptions={filterOptions}
                              renderInput={(params) => (
                                <TextField
                                  placeholder="City where you were born"
                                  variant="standard"
                                  {...params}
                                  fullWidth
                                  // disabled={!selectedCountry1}
                                  InputProps={{
                                    disableUnderline: true,
                                    ...params.InputProps,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {loadingCities && isTyping ? (
                                          <CircularProgress size={20} />
                                        ) : null}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {!personalInfo.cityOfBirth && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                          {/* FormControl for Country of Eligibility */}
                          <FormControl variant="standard">
                            <InputLabel
                              style={{ marginTop: "-5px" }}
                              shrink
                              htmlFor="countryEligibility-input"
                            >
                              Country of Eligibility
                              <Tooltip
                                placement="top"
                                title="If your own country doesn't qualify, you might be able to use your spouse's or parents' birth country instead."
                              >
                                <IconButton>
                                  <Info />
                                </IconButton>
                              </Tooltip>
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>

                            <Autocomplete
                              // disabled={showEdit}
                              className={`input-auto ${!personalInfo.countryOfEligibility && promptInfo ? "error-border" : ""}`}
                              noOptionsText="Type to search"
                              value={personalInfo.countryOfEligibility}
                              onChange={handleCountrySelect2}
                              // inputValue={countryInput2}
                              inputValue={countryInput2 || ""}
                              onInputChange={handleCountryInput2Change}
                              disableClearable
                              // onInputChange={(event, newInputValue) =>
                              //   setCountryInput2(newInputValue)
                              // }
                              options={filteredCountries2}
                              filterOptions={filterOptions}
                              renderInput={(params) => (
                                <TextField
                                  placeholder="Country of Eligibility"
                                  // sx={{ padding: "5px" }}
                                  variant="standard"
                                  {...params}
                                  fullWidth
                                  InputProps={{
                                    disableUnderline: true,
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {loadingCountries ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {/* {params.InputProps.endAdornment} */}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {!personalInfo.countryOfEligibility &&
                            promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>

                <Grid item display="flex" marginTop="20px" flexDirection="row" xs={12}>
                  <Item>
                    <Grid
                      display="flex"
                      container
                      flexDirection="row"
                      flexGrow={1}
                      rowSpacing={2}
                      columnSpacing={2}
                    >
                      <Grid item display="flex" flexDirection="column" xs={12}>
                        <Grid
                          container
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Grid
                            id="eligibilityBox"
                            item
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            {/* Icon for the section */}
                          </Grid>
                          <Grid item display="flex" flexDirection="column">
                            {/* Title of the section */}
                            <Typography
                              variant="h5"
                              fontWeight="bold"
                              textAlign="left"
                              ml={0}
                            >
                              Education Level
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item display="flex" flexDirection="row" xs={12}>
                        {/* Form control for the grid */}
                        <Box
                          component="form"
                          noValidate
                          width="100%"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              md: "repeat(2, 1fr)",
                              lg: "repeat(3, 1fr)",
                            },
                            gap: 2,
                            rowGap: 4,
                          }}
                        >
                          {/* FormControl for Country where you were born */}
                          <FormControl variant="standard">
  <InputLabel shrink htmlFor="education-input">
    What is your highest degree of education?
    <Typography component="span" color="error">*</Typography>
  </InputLabel>
  <Autocomplete
    className={`input-auto ${!personalInfo.education && promptInfo ? "error-border" : ""}`}
    disableClearable
    freeSolo // Allow free text input
    options={educationOptions}
    value={personalInfo.education || ""}
    onChange={(event, newValue) => handleEducationChange(newValue)} // Use the new handler
    renderInput={(params) => (
        <TextField {...params} placeholder="Select education level" variant="standard" fullWidth InputProps={{
            disableUnderline: true,
            ...params.InputProps, endAdornment: (
                <>
                    {loadingCountries ? (
                        <CircularProgress
                            color="inherit"
                            size={20}
                        />
                    ) : null}
                </>
            )
        }} />
    )}
/>
</FormControl>


{/* Conditionally render Job Experience input */}
{["No high school at all", "Some high school, no degree (less than 12 years of school)", "High school, no degree (12+ years of school)"].includes(personalInfo.education) && (
  <FormControl variant="standard">
  <InputLabel shrink htmlFor="job-experience-input">
    Do you have job experience?
    <Typography component="span" color="error">*</Typography>
  </InputLabel>
  <Autocomplete
    className={`input-auto ${!personalInfo.jobExperience && promptInfo ? "error-border" : ""}`}
    disableClearable
    freeSolo // Allow free text input
    options={["yes", "no"]} // You can keep these options or remove them
    value={personalInfo.jobExperience || ""}
    onChange={handleJobExperienceChange}
    renderInput={(params) => (
        <TextField {...params} placeholder="Select job experience" variant="standard" fullWidth InputProps={{
            disableUnderline: true,
            ...params.InputProps, endAdornment: (
                <>
                    {loadingCountries ? (
                        <CircularProgress
                            color="inherit"
                            size={20}
                        />
                    ) : null}
                </>
            )}} />
    )}
  />
</FormControl>
)}
                     
                        </Box>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>

              </Grid>

{/* Photo */}
              <Grid
                marginLeft={isXs || isSm ? "30px" : ""}
                display="flex"
                container
                justifyContent="flex-end"
                flexDirection="row"
                alignItems="flex-end"
              >
                <Grid item display="flex" flexDirection="row" xs={12}>
                  <Item>
                    <Grid
                      display="flex"
                      container
                      flexDirection="row"
                      rowSpacing={2}
                      columnSpacing={2}
                    >
                      <Grid
                        id="photoBox"
                        item
                        display="flex"
                        flexDirection="row"
                        xs={12}
                      >
                        <Grid
                          item
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <FontAwesomeIcon icon={faCamera} size="xl" />
                        </Grid>
                        <Grid item display="flex" flexDirection="column">
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="left"
                            ml={2}
                          >
                            Photograph
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        xs={12}
                        md={6}
                        lg={4}
                      >
                        <FormControl variant="standard" fullWidth>
                          {/* <Grid
                            container
                            display="flex"
                            flexDirection="column"
                            alignItems="start"
                            flexGrow={1}
                          > */}
                          {/* <Grid item display="flex" flexDirection="column"> */}
                          <InputLabel shrink htmlFor="photo-input">
                            Upload your photograph
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          </InputLabel>

                          <CustomInputStyleImg
                            error={
                              fileTypeError ||
                              (!personalInfo.photo && promptInfo)
                            }
                            // disabled={showEdit}
                            title={file}
                            name="photo"
                            placeholder="Photograph"
                            type="file"
                            id="photo-input"
                            accept="image/jpeg"
                            onChange={handleChange}
                            // InputProps={{
                            //   endAdornment:(
                            //     <InputAdornment position="end">
                            //       {isLoading && isLoadingPhoto ? (
                            //         <CircularProgress size={20} />
                            //       ) : (
                            //         ""
                            //       )}
                            //     </InputAdornment>
                            //   )
                            // }}
                          />
                          {
                            personalInfo.photo && (
                              <Box mt={2}>
                                <Typography variant="body1" display="flex">
                                  Preview:
                                </Typography>
                                <img
                                  src={personalInfo.photo}
                                  alt="Uploaded"
                                  title={file}
                                  style={{
                                    maxWidth: "100%",
                                    // maxHeight: "350px",
                                    marginTop: "10px",
                                  }}
                                />
                              </Box>
                            )
                            //  (
                            //   <img
                            //     className="personal-img"
                            //     src={personalInfo.photo}
                            //     title={file}
                            //     alt="photograph"
                            //     width="335px"
                            //     height="350px"
                            //   />
                            // )
                          }
                          {/* </Grid> */}
                          {/* </Grid> */}
                          {fileTypeError ? (
                            <Typography
                              color="red"
                              variant="body1"
                              textAlign="left"
                            >
                              Please select a jpg file.
                            </Typography>
                          ) : (
                            ""
                          )}
                          {!personalInfo.photo && promptInfo ? (
                            <Typography
                              color="red"
                              variant="body1"
                              textAlign="left"
                            >
                              *Required field
                            </Typography>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Item>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
