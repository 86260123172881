import React, { useCallback, useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { PersonalInfoContext } from "../../../context/PersonalInfoContext";
import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const { personalInfo, setPersonalInfo, resetPersonalInfo } =
    useContext(PersonalInfoContext);

  const fetchClientSecret = useCallback(() => {
    const csrfToken = localStorage.getItem("csrfToken");
    const authToken = localStorage.getItem("authToken");
    const headers: Record<string, string> = {
      authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    if (csrfToken) {
      headers["Csrf-Token"] = csrfToken;
    }
    return fetch(
      `${import.meta.env.VITE_API_SERVER_URL}/payment/create-checkout-session`,
      {
        method: "POST",
        credentials: "include",
        headers,
      },
    )
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    const csrfToken = localStorage.getItem("csrfToken");
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (csrfToken) {
      headers["Csrf-Token"] = csrfToken;
    }

    if (sessionId !== null) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      // Push success event to data layer
      (window as any).dataLayer.push({
        event: "begin_checkout",
        transaction_id: `${sessionId}`, // Replace with actual transaction ID
        value: personalInfo.payment_status === "open" ? 1.0 : 35.0, // Replace with actual value
        currency: "USD",
      });
    }

    // Only make the API call if the session_id is present
    if (sessionId !== null && !personalInfo.payment_status) {
      fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/payment/session-status?session_id=${sessionId}`,
        {
          method: "GET",
          credentials: "include",
          headers,
        },
      )
        .then((res) => res.json())
        .then((data) => {
          setPersonalInfo("payment_status", data.status);
          setCustomerEmail(data.customer_email);

          if (data.status === "complete") {
            (window as any).dataLayer = (window as any).dataLayer || [];
            // Push success event to data layer
            (window as any).dataLayer.push({
              event: "purchase",
              transaction_id: `${sessionId}`, // Replace with actual transaction ID
              value: 35.0, // Total payment value
              currency: "USD",
              status: data.status || "done",
            });
          }

          // Modify the URL to remove the session_id but keep the application path
          const url = new URL(window.location.href);
          url.searchParams.delete("session_id");
          window.history.replaceState(null, "", url.pathname); // Replace the URL without reloading the page
        })
        .catch((error) => {
          console.error("Error fetching session status:", error);

          // Push failure event to data layer
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: "purchase_failed",
            error: error.message || "Payment failed",
            transaction_id: `${sessionId}`, // Replace with actual transaction ID
            value: 35.0, // Total payment value
            currency: "USD",
          });
          // Optionally, set an error state here to inform the user
        });
    } else {
      // Retain the URL without the session_id if it doesn't exist
      const url = new URL(window.location.href);
      if (urlParams.has("session_id")) {
        url.searchParams.delete("session_id");
        window.history.replaceState(null, "", url.pathname);
      }
    }
  }, [personalInfo.payment_status, setPersonalInfo]);

  const options = { fetchClientSecret };

  return (
    <div
      id="checkout"
      style={
        personalInfo.payment_status === "complete"
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            } // No styles when payment is complete
          : {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              textAlign: "center",
              width: "90%",
            }
      }
    >
      {!personalInfo.payment_status || personalInfo.payment_status === "open" ? (
        <div style={{ flex: 0.8, padding: "20px", textAlign: "left" }}>
          <h2>$1 Plus Promotion - Overview</h2>
          <p>
            Initial Payment: Begin with a small, symbolic payment of just $1 to secure your place in the Green Card Lottery program. This minimal upfront cost ensures your spot is reserved and your application process is set in motion.
          </p>
          <p>
            Completion: After the official Diversity Visa (DV) Lottery submission, you will be required to pay an additional $34. This completes the payment, covering all services related to the submission and ensuring your participation.
          </p>
          <h3>Benefits:</h3>
          <ul>
            <li>Guaranteed Submission: Your Green Card Lottery application will be carefully reviewed and submitted within the current lottery period, giving you the best chance at success.</li>
            <li>No Further Payments: Once the $34 is paid, no additional costs are required, providing you with a stress-free, simple process.</li>
          </ul>
          <p>
            This promotion offers a cost-effective, straightforward solution to participate in the Green Card Lottery with full service and support from start to finish. Enjoy peace of mind knowing your application is in professional hands, and no extra payments will be necessary. <a href="/account/termsandconditions">Learn more...</a>
          </p>
        </div>
      ) : null}

      {!personalInfo.payment_status || personalInfo.payment_status === "open" ? (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        ""
      )}

      {personalInfo.payment_status === "complete" ? (
        <>
          <Typography id="thank-you-text" variant="h5">
            Thank You! Your payment was successfully processed
          </Typography>
          <Grid
            mt={2}
            item
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <FontAwesomeIcon icon={faCircleCheck} size="3x" color="#C54B3A" />
          </Grid>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Payment;
