import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Link, Link as RouterLink } from "react-router-dom";
import "./_PathWay.scss";


import Label4 from "../../assets/images/label 4.png";

export default function Pathway() {
  return (
    <Container
      maxWidth="xl"
      component="section"
      className="pathway-container"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fdfdfd",
        padding: { xs: 2, sm: 3, md: 6, lg: 8, xl: 10 },
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={4}
      >
        <Grid item>
          <Typography variant="h4">
          $1 Plus Promotion 
          </Typography>
          <Typography variant="h6">
          Start your application for just $1 today, and pay the rest after we submit it on your behalf. Terms and Conditions apply. <Link to="https://www.immigrateable.com/account/termsandconditions" >
         Learn more...
      </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        flexDirection="row"
        columnSpacing={4}
        alignItems="stretch"
        justifyContent="center"
        rowSpacing={2}
        mt={2}
        columnGap={4}
        rowGap={4}
      >
        <Grid
        className="pro_plan"
          item
          xs={8}
          md={3.5}
          display="flex"
          flexDirection="column"
          sx={{ minHeight: "400px", backgroundColor: "#fff" }}
          padding={4}
          borderRadius={4}
          border="1px solid #FCEDEC"
          color="#113854"
          alignItems="stretch"
        >
           <img
            width="100%"
            src={Label4}
            alt="Begin Your Journey to the American Dream with EasyDV"
            className="label_img"
          />
          <Grid item display="flex" alignSelf="center" flexDirection="row">
            <Typography variant="h5" sx={{ mt: 4, mb: 3 }}>
            Pro
            </Typography>
          </Grid>
          <Grid item display="flex" alignSelf="center" flexDirection="row">
            <Typography variant="h6" sx={{ color: "#C54B3A", mb: 2, fontSize: '2rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
              <span>$35</span> 
            </Typography>
          </Grid>
          <Grid item display="flex" alignSelf="flex-start" flexDirection="row">
            <List>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Year-Round Application Acceptance" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Application Compliance Review" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Photo Compliance Verification" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="User Login Account" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Free English Course" />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            display="flex"
            alignSelf="center"
            flexDirection="row"
            flexGrow={1}
          />
          <Grid item display="flex" justifySelf="flex-end" flexDirection="row">
            <Button
              id="apply-now-1"
              className="dark-blue-text"
              component={RouterLink}
              to="/application/personal-details"
              fullWidth
              sx={{
                marginTop: 4,
                marginBottom: 3,
                borderRadius: 5,
                backgroundColor: "#C54B3A",
                px: 4,
                color: "white",
                "&:hover": {
                  backgroundColor: "#083657",
                },
              }}
            >
              Start For $1
            </Button>
          </Grid>
        </Grid>
        <Grid
        className="elite_plan"
          item
          xs={8}
          md={3.5}
          display="flex"
          flexDirection="column"
          sx={{ minHeight: "400px", backgroundColor: "#113854" }}
          padding={4}
          borderRadius={4}
          border="1px solid #FCEDEC"
          color="#fff"
        >
           <img
            width="100%"
            src={Label4}
            alt="Begin Your Journey to the American Dream with EasyDV"
            className="label_img"
          />
          <Typography variant="h5" sx={{ mt: 4, mb: 3 }}>
            Elite
          </Typography>
          <Typography variant="h6" sx={{ color: "#FCEDEC", mb: 2, fontSize: '2rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
            <span>$60</span> 
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Automatic Re-application (Bonus: $35 Value Free)" />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Re-apply Notifications" />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Certificate of Participation" />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Free English Course" />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Application Compliance Review" />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                <CheckIcon sx={{ color: "#C54B3A" }} />
              </ListItemIcon>
              <ListItemText primary="Year-Round Application Acceptance" />
            </ListItem>
          </List>
          <Button
            id="apply-now-2"
            className="dark-blue-text"
            component={RouterLink}
            to="/application/personal-details"
            sx={{
              marginTop: 5,
              marginBottom: 3,
              borderRadius: 5,
              backgroundColor: "#C54B3A",
              px: 4,
              color: "white",
              "&:hover": {
                backgroundColor: "#083657",
              },
            }}
          >
            Start For $1
          </Button>
        </Grid>
        <Grid
          item
          xs={8}
          md={3.5}
          display="flex"
          flexDirection="column"
          sx={{ minHeight: "400px", backgroundColor: "#fff" }}
          padding={4}
          borderRadius={4}
          border="1px solid #FCEDEC"
          color="#113854"
          alignItems="stretch"
          className="vip_plan"
        >
           <img
            width="100%"
            src={Label4}
            alt="Begin Your Journey to the American Dream with EasyDV"
            className="label_img"
          />
          <Grid item display="flex" alignSelf="center" flexDirection="row">
            <Typography variant="h5" sx={{ mt: 4, mb: 3 }}>
            VIP
            </Typography>
          </Grid>
          <Grid item display="flex" alignSelf="center" flexDirection="row">
            <Typography variant="h6" sx={{ color: "#C54B3A", mb: 2, fontSize: '2rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
              <span>$99</span> 
            </Typography>
          </Grid>
          <Grid item display="flex" alignSelf="flex-start" flexDirection="row">
            <List>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Interview Preparation" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="After Winning Assistance" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Flight Ticket Reimbursement" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Automatic Re-application (Bonus: $35 Value Free)" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Winning Notifications" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Certificate of Participation" />
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ mr: 0, minWidth: "36px" }}>
                  <CheckIcon sx={{ color: "#C54B3A" }} />
                </ListItemIcon>
                <ListItemText primary="Unlimited Access/Changes" />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            display="flex"
            alignSelf="center"
            flexDirection="row"
            flexGrow={1}
          />
          <Grid item display="flex" justifySelf="flex-end" flexDirection="row">
            <Button
              id="apply-now-3"
              className="dark-blue-text"
              component={RouterLink}
              to="/application/personal-details"
              fullWidth
              sx={{
                marginTop: 4,
                marginBottom: 3,
                borderRadius: 5,
                backgroundColor: "#C54B3A",
                px: 4,
                color: "white",
                "&:hover": {
                  backgroundColor: "#083657",
                },
              }}
            >
              Start For $1
            </Button>
          </Grid>
        </Grid>
        
      </Grid>
    </Container>
  );
}
