// PersonalInfoContext.tsx
import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
  // useContext,
  useCallback,
} from "react";
// import { ContactInfoContext } from "./ContactInfoContext";
import refreshAccessToken from "../utils/fetchWithToken"; // Adjust the path as needed

// Assuming useAuth is defined elsewhere in your application

export interface PersonalInfo {
  userId?: string | null;
  id: string;
  firstname: string;
  middlename: string;
  lastname: string;
  dob: string | null;
  gender: string;
  countryOfBirth: string;
  countryOfEligibility: string;
  cityOfBirth: string;
  photo: string | undefined;
  email: string;
  // phone: string;
  applicationId: string;
  // children: string[];
  payment_status: string;
  education: string; // Add this line
  jobExperience: string; // Add this line
}

const userID = localStorage.getItem("userId");
console.log(userID);

export const defaultPersonalInfo: PersonalInfo = {
  countryOfEligibility: "",
  photo: undefined,
  userId: userID,
  firstname: "",
  middlename: "",
  lastname: "",
  dob: null,
  gender: "",
  email: "",
  // phone: "",
  cityOfBirth: "",
  countryOfBirth: "",
  applicationId: "",
  // children: [],
  id: "",
  payment_status: "",
  education: "", // Add this line
  jobExperience: "", // Add this line
};

interface PersonalInfoContextType {
  personalInfo: PersonalInfo;
  setPersonalInfo: (
    name: keyof PersonalInfo, // Use keyof PersonalInfo to ensure type safety
    value: PersonalInfo[keyof PersonalInfo] | File | undefined,
  ) => void;
  resetPersonalInfo: () => void;
  isLoading: boolean;
}

export const PersonalInfoContext = createContext<PersonalInfoContextType>({
  personalInfo: defaultPersonalInfo,
  setPersonalInfo: () => {},
  resetPersonalInfo: () => {},
  isLoading: false,
});

export const PersonalInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [personalInfo, setPersonalInfo] =
    useState<PersonalInfo>(defaultPersonalInfo);

  // const { contactInfo, setContactInfo } = useContext(ContactInfoContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeoutIdRef = useRef<number | null>(null);

  // const fetchPersonalInfo = async () => {
  //   const csrfToken = localStorage.getItem("csrfToken") || "";
  //   const applicationId = window.location.pathname
  //     .split("/application/")[1]
  //     .split("/")[0];
  //   try {
  //     let authToken = localStorage.getItem("authToken");

  //     let response = await fetch(
  //       `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${applicationId}`,
  //       {
  //         method: "GET",
  //         credentials: "include",
  //         headers: {
  //           authorization: `Bearer ${authToken}`,
  //           "Content-Type": "application/json",
  //           "CSRF-Token": csrfToken,
  //         },
  //       },
  //     );

  //     if (response.status === 403) {
  //       console.log("Received 403 status, refreshing token...");
  //       await refreshAccessToken();
  //       authToken = localStorage.getItem("authToken"); // Update authToken after refresh

  //       // Retry the request with the new token
  //       response = await fetch(
  //         `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${applicationId}`,
  //         {
  //           method: "GET",
  //           credentials: "include",
  //           headers: {
  //             authorization: `Bearer ${authToken}`,
  //             "Content-Type": "application/json",
  //             "CSRF-Token": csrfToken,
  //           },
  //         },
  //       );
  //     }

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();
  //     const personalinfo = {
  //       id: data.id,
  //       applicationId: data.applicationId,
  //       userId: data.userId,
  //       firstname: data.firstname,
  //       middlename: data.middlename,
  //       lastname: data.lastname,
  //       dob: data.dob,
  //       gender: data.gender,
  //       countryOfBirth: data.countryOfBirth,
  //       cityOfBirth: data.cityOfBirth,
  //       countryOfEligibility: data.countryOfEligibility,
  //       email: data.email,
  //       photo: data.photo, // Assign from data if available
  //     };
  //     localStorage.setItem("personalInfo", JSON.stringify(personalinfo));
  //     setPersonalInfo(personalinfo);
  //   } catch (error) {
  //     console.error("Failed to fetch personal info:", error);
  //   }
  // };

  useEffect(() => {
    // Load initial state from localStorage
    const storedInfo = localStorage.getItem("personalInfo");
    if (storedInfo && storedInfo !== "undefined") {
      setPersonalInfo(JSON.parse(storedInfo));
    }
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const csrfToken = localStorage.getItem("csrfToken") || "";
      const formData = localStorage.getItem("formData") || "";

      if (!formData) {
        // console.log("No user logged in");
        return; // Exit if no userId found
      }
      const userId = JSON.parse(formData)?.userId;

      if (!userId) {
        console.log("No user logged in");
        return; // Exit if no userId found
      }

      try {
        const authToken = localStorage.getItem("authToken");
        const response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${userId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "CSRF-Token": csrfToken,
            },
          },
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("User details fetched:", data);

        setPersonalInfo((prevPersonalInfo) => ({
          ...prevPersonalInfo,
          email: data.email,
        }));

        localStorage.setItem("formData", JSON.stringify(data));
        return data;
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails()
      .then((r) => console.log("r", r))
      .catch((e) => console.error(e, "e"));
  }, [personalInfo.id]);

  const savePersonalInfo = useCallback((newInfo: PersonalInfo) => {
    setIsLoading(true);
    const csrfToken = localStorage.getItem("csrfToken") || "";

    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }

    const authToken = localStorage.getItem("authToken");
    const tempToken = localStorage.getItem("tempToken");

    const getCookie = (name: string): string | null => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
      return null;
    };
    
    const getUtmParams = () => {
      const utmSource = getCookie("utm_source");
      const utmMedium = getCookie("utm_medium");
      const utmCampaign = getCookie("utm_campaign");
      const utmId = getCookie("utm_id");
      const userID = getCookie("userID");
    
      return {
        utm_source: utmSource || "",
        utm_medium: utmMedium || "",
        utm_campaign: utmCampaign || "",
        utm_id: utmId || "",
        userID: userID || "",
      };
    };

    const utmParams = getUtmParams();

    // Set a new timeout
    timeoutIdRef.current = window.setTimeout(async () => {
      const { id } = newInfo; // Destructure id from newInfo
      console.log(newInfo);
      console.log(id);

      const httpMethod = id ? "PATCH" : "POST";
      const endpoint = id
        ? `/${import.meta.env.VITE_APPLICATION_URL}/${id}`
        : `/${import.meta.env.VITE_APPLICATION_URL}`;

      const validateEmail = (email: string): boolean => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email);
      };

      const isValid = validateEmail(newInfo.email);
      if (isValid) {
        try {
          let response = await fetch(
            `${import.meta.env.VITE_API_SERVER_URL}${endpoint}`,
            {
              method: httpMethod,
              credentials: "include",
              headers: {
                authorization: `Bearer ${authToken}`,
                verifyTempToken: `${tempToken}`,
                "Content-Type": "application/json",
                "CSRF-Token": csrfToken, // Ensure this header matches what your server expects
              },
              body: JSON.stringify({ ...newInfo, utmParams }),
            },
          );
          if (response.status === 403) {
            console.log("Received 403 status, refreshing token...");
            await refreshAccessToken();
            const newAuthToken = localStorage.getItem("authToken"); // Update authToken after refresh

            // Retry the request with the new token
            response = await fetch(
              `${import.meta.env.VITE_API_SERVER_URL}${endpoint}`,
              {
                method: httpMethod,
                credentials: "include",
                headers: {
                  authorization: `Bearer ${newAuthToken}`,
                  "Content-Type": "application/json",
                  "CSRF-Token": csrfToken,
                },
                body: JSON.stringify({ ...newInfo, ...utmParams }),
                
              },
            );
          }

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setIsLoading(false);

          if (httpMethod === "POST" && data.id) {
            const { tempToken } = data;
            console.log(authToken);
            localStorage.setItem("tempToken", tempToken);
            localStorage.setItem("userId", data.userId);
            localStorage.setItem("personalInfoId", data.id);
            setPersonalInfo((prevPersonalInfo) => {
              const newUserData = {
                ...prevPersonalInfo,
                id: data.id, // Set id from response
                userId: data.userId,
              };
              console.log(data);
              localStorage.setItem("personalInfo", JSON.stringify(newUserData));
              localStorage.setItem("personalInfoId", newUserData.id);
              console.log(newUserData);
              return newUserData;
            });
          } else if (httpMethod === "PATCH") {
            setPersonalInfo((prevPersonalInfo) => {
              // Update personalInfo with existing values
              const updatedInfo = { ...prevPersonalInfo, ...data };
              localStorage.setItem("personalInfo", JSON.stringify(updatedInfo));
              localStorage.setItem("personalInfoId", updatedInfo.id);
              return updatedInfo;
            });
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      }
    }, 1000); // Debounce period of 1 second
  }, []);

  const updatePersonalInfo = useCallback(
    (
      name: keyof PersonalInfo,
      value: PersonalInfo[keyof PersonalInfo] | File | undefined,
    ) => {
      setPersonalInfo((prevInfo) => {
        const updatedInfo = { ...prevInfo, [name]: value };
        if (updatedInfo.applicationId != "") {
          localStorage.setItem("personalInfo", JSON.stringify(updatedInfo));
        }
        savePersonalInfo(updatedInfo);
        return updatedInfo;
      });
    },
    [savePersonalInfo],
  );

  const resetPersonalInfo = () => {
    setPersonalInfo(defaultPersonalInfo);
    localStorage.removeItem("personalInfo"); // Clear from local storage
  };

  return (
    <PersonalInfoContext.Provider
      value={{
        personalInfo,
        setPersonalInfo: updatePersonalInfo,
        resetPersonalInfo,
        isLoading,
      }}
    >
      {children}
    </PersonalInfoContext.Provider>
  );
};
