import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const Conditions = () => {
  return (
    <>
      <Typography paragraph>
        <Typography
          variant="h4"
          fontWeight="bold"
          justifyContent="center"
          display="flex"
          marginBottom="10px"
          margin="50px"
        >
          $1 Plus Promotion Terms and Conditions
        </Typography>
        <Typography>
          <strong>Overview:</strong> The $1 promotion allows you to initiate
          your application with a nominal $1 payment, ensuring your submission
          is prepared and professionally managed. To complete the process and
          receive your DV Lottery official confirmation, a balance of $34 will
          be required after submission.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Promotion Terms:
        </Typography>
        <Typography>
          <strong>Initial Payment of $1:</strong> With just a $1 payment, we
          will initiate and manage your Green Card Lottery application, ensuring
          it complies with the program’s regulations. This straightforward
          process gives you peace of mind and confidence in your application.
        </Typography>
        <Typography>
          <strong>Post-Submission Payment of $34:</strong> Once we confirm the
          submission of your application to the DV Lottery, you must complete
          the remaining payment of $34 to receive your official DV Lottery
          submission confirmation number. This confirmation is essential for
          tracking your entry and viewing the results.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Payment Deadline:
        </Typography>
        <Typography>
          <strong>Balance Due Date:</strong> To finalize your participation in
          the $1 Promotion, the $34 balance must be paid by December 31st of the
          same year your application is submitted. This deadline is
          non-negotiable and critical for receiving your confirmation number.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Automatic Transition to Premium+ Plan:
        </Typography>
        <Typography>
          <strong>Failure to Pay by Deadline:</strong> If you fail to pay the
          balance of $34 by December 31st, you will be automatically
          transitioned to the Premium+ Plan, which offers a different payment
          structure:
        </Typography>
        <Typography>
          No Immediate Payment: No further payments will be required unless your
          application is selected in the DV Lottery.
        </Typography>
        <Typography>
          <strong>$999 Due Upon Selection:</strong> If your application is
          selected under the Premium+ Plan, a one-time payment of $999 will be
          required to secure our full-service support for the following stages
          of your immigration process.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Consequences of Non-Payment:
        </Typography>
        <Typography>
          <strong>Loss of Submission Confirmation Number:</strong> If the $34
          balance is not paid by the deadline, you will forfeit your right to
          receive the DV Lottery submission confirmation number. Without this
          number, you cannot independently verify your application's status.
        </Typography>
        <Typography>
          <strong>Enrollment in Premium+ Plan:</strong> Failure to pay the
          remaining balance by the stated deadline automatically enrolls you
          into the Premium+ Plan, and you will only be charged if your
          application is selected as a DV Lottery winner.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Premium+ Plan Terms:
        </Typography>
        <Typography>
          <strong>$999 Due Upon Selection:</strong> Under the Premium+ Plan,
          should your application be selected, a $999 payment will be required
          to access full-service assistance, including legal support, for the
          next steps of your immigration process.
        </Typography>
        <Typography>
          <strong>Full-Service Support:</strong> Upon selection and payment, our
          team will guide you through the necessary immigration procedures,
          ensuring your journey is handled with precision and expertise. This
          comprehensive support will make you feel secure and confident in your
          immigration process.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Legal Obligations:
        </Typography>
        <Typography>
          <strong>Non-Refundable Payments:</strong> The initial $1 payment and
          any further payments under the $1 Promotion are non-refundable.
        </Typography>
        <Typography>
          <strong>Commitment to Payment:</strong> You agree to fulfill the
          outlined financial obligations by participating in this promotion. The
          Premium+ Plan requires the $999 payment to secure your winning status
          and immigration support if selected. We are committed to providing you
          with a clear understanding of the terms and conditions.
        </Typography>
        <Typography>
          <strong>Data Privacy and Confidentiality:</strong> We adhere to strict
          data protection regulations. Your personal information will be
          safeguarded and used solely for processing your Green Card Lottery
          application, ensuring your privacy and security.
        </Typography>
      </Typography>

      <Typography paragraph>
        <Typography variant="h6" fontWeight="bold" marginBottom="10px">
          Conclusion:
        </Typography>
        <Typography>
          The $1 Promotion offers an exceptional opportunity to begin your Green
          Card Lottery application with minimal financial commitment. To ensure
          your participation and secure your confirmation number, timely payment
          of the $34 balance is required. If the deadline is missed, your
          application will transition to the Premium+ Plan with no additional
          costs unless you are selected. Our legal team is here to provide
          comprehensive support throughout the process, ensuring your best
          chance of success in the DV Lottery.
        </Typography>
      </Typography>
    </>
  );
};

export default Conditions;
