import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import "./_TermsAndConditions.scss";
import {
    Container,
    Grid,
    Typography
} from "@mui/material";
import Terms from './Terms';
import Conditions from './Conditions';

const TermsAndConditions = () => {

  // Logic to scroll down 50% after the component is mounted
  useEffect(() => {
    const scrollToMiddle = () => {
      const scrollHeight = document.body.scrollHeight;
      window.scrollTo({
        top: scrollHeight * 0.58,  // Scroll to 50% of the total page height
        behavior: "smooth",       // Smooth scrolling effect
      });
    };

    // Trigger the scroll after a slight delay to ensure content has loaded
    setTimeout(scrollToMiddle, 500);
  }, []);

  return (
    <Container>
      <Grid textAlign="left">
        <Typography
          textAlign="center"
          variant="h4"
          fontWeight="bold"
          className="privacy"
          style={{ marginBottom: "16px" }}
        >
          Terms and Conditions
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            1. Introduction
          </Typography>
          <Typography>
            Welcome to <Link to="https://immigrateable.com" target="_blank">Immigrateable.com</Link>. These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our site, you agree to abide by these Terms. If you do not agree, please refrain from using our services.
          </Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            2. Use of Services
          </Typography>
          <Typography><strong>Eligibility:</strong> You must be at least 18 years old to use our services.</Typography>
          <Typography><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            3. Application Process
          </Typography>
          <Typography><strong>Accuracy of Information:</strong> You must provide accurate and complete information when submitting your DV Lottery application. Inaccuracies may result in the rejection of your application.</Typography>
          <Typography><strong>Submission:</strong> We will review and submit your application to the U.S. authorities during the official submission period. We are not responsible for applications rejected due to errors outside our control.</Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            4. Fees and Payment
          </Typography>
          <Typography><strong>Service Fees:</strong> All fees for our services are listed on our website and must be paid in full before we process your application.</Typography>
          <Typography><strong>Refund Policy:</strong> Once the application process has begun, fees are non-refundable unless due to errors on our part.</Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            5. Data Protection
          </Typography>
          <Typography><strong>Privacy Policy:</strong> Your personal data is protected in accordance with our Privacy Policy. By using our services, you consent to the collection and use of your data as outlined in our Privacy Policy.</Typography>
          <Typography><strong>Data Security:</strong> We implement appropriate security measures to protect your personal data from unauthorized access.</Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            6. Intellectual Property
          </Typography>
          <Typography><strong>Ownership:</strong> All content on <Link to="https://immigrateable.com" target="_blank">Immigrateable.com</Link>, including text, graphics, logos, and software, is the property of Immigrateable or its content suppliers and is protected by international copyright laws.</Typography>
          <Typography><strong>Use Restrictions:</strong> You may not reproduce, distribute, or create derivative works from our content without express written permission from Immigrateable.</Typography>
        </Typography>

        <Typography paragraph>
          <Typography variant="h6" fontWeight="bold" marginBottom="10px">
            7. Limitation of Liability
          </Typography>
          <Typography><strong>Service Accuracy:</strong> While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy, completeness, or reliability of any content on our website.</Typography>
          <Typography><strong>Liability Cap:</strong> Our liability for any claims arising from your use of our services is limited to the amount you paid for those services. We are not liable for any indirect, incidental, or consequential damages.</Typography>
        </Typography>

        {/* Other sections */}
        <Terms />
        <Conditions />
      </Grid>
    </Container>
  );
}

export default TermsAndConditions;
